<template>
  <div class="w-full h-screen overflow-x-hidden">
    <div class="flex items-center justify-center w-full h-screen">
      <!-- Desktop view -->
      <div class="hidden w-1/2 h-full border lg:block">
        <div class="block w-full h-full text-center">
          <img
            class="object-cover w-full h-full"
            src="~/public/images/kol_banner.png"
            alt="logo"
          />
        </div>
      </div>
      <div
        class="flex-col items-center justify-center hidden w-1/2 h-full bg-black lg:flex"
      >
        <slot />
      </div>

      <!-- Mobile view -->
      <div class="relative w-full h-full lg:hidden">
        <img
          class="absolute inset-0 object-cover w-full h-full"
          src="~/public/images/kol_banner.png"
          alt="logo"
        />
        <div
          class="relative z-10 flex flex-col items-center justify-center w-full h-full bg-black/70"
        >
          <slot />
        </div>
      </div>
    </div>
  </div>
</template>
